<template>
    <div class="home">

        <Header
            type="large"
            :title="data['home_title_' + lang]"
            :text="data['home_text_' + lang]"
            :image="data.home_image"
            :image_caption="data['home_image_title_' + lang]"
            :actions="[
                {
                    title: $t('products.actions.our_products'),
                    class: 'bg-accent mb-r',
                    href: '#assortiment',
                },
                {
                    title: $t('contact.menu'),
                    to: 'Contact'
                }
            ]"
        >
            <template v-slot:after>
                <div class="size-6 color-secondary mt-xl">
                    <div
                        v-for="usp in usps" :key="'usp_' + usp.icon"
                        class="flex align-center py-s"
                    >
                        <div class="mr-m">
                            <img :src="'/img/icon_' + usp.icon + '.svg'" class="w-32 h-32" />
                        </div>
                        <div>
                            {{ usp.usp }}
                        </div>
                    </div>
                </div>
            </template>
        </Header>

        <Introduction
            :title="data['about_title_' + lang]"
            :text="data['about_text_' + lang]"
            :image1="data.about_image1"
            :image2="data.about_image2"
            :image_caption1="data['about_image1_title_' + lang]"
            :image_caption2="data['about_image2_title_' + lang]"
            :actions="[
                {
                    title: $t('about.actions.more'),
                    class: 'bg-accent',
                    to: '/' + lang + '/over-ons',
                },
            ]"
        />

        <div id="assortiment" name="assortiment" class="c w-xxl">
            <Products
                :list="data.products_categories"
                to="Assortiment"
            />
        </div>

    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Introduction from '@/components/Introduction.vue';
import Products from '@/components/Products.vue';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'Home',
    components: {
        Header,
        Introduction,
        Products,
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },

        data() {
            return this.$store.getters.data;
        },

        usps() {
            const icons = [
                'trees', 'globe', 'coffee', 'award',
            ];

            const usps = [];
            for (let i = 0; i < 4; i += 1) {
                const usp = this.data[`usp_${i + 1}_${this.lang}`];
                if (usp) {
                    usps.push({
                        usp,
                        icon: icons[i],
                    });
                }
            }
            return usps;
        },
    },
    methods: {
        kebabcase,

        setMeta() {
            if (this.data) {
                setTimeout(() => {
                    let schemas = [];
                    if (this.$route.meta.schema) {
                        if (typeof this.$route.meta.schema === 'function') {
                            schemas = this.$route.meta.schema(Array.isArray(this.data.products_categories) ? this.data.products_categories : [], this);
                        } else {
                            schemas = this.$route.meta.schema;
                        }
                    }
                    this.$store.commit('setMeta', {
                        schemas,
                    });
                }, 10);
            }
        },
    },
    watch: {
        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>
