var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Header',{attrs:{"type":"large","title":_vm.data['home_title_' + _vm.lang],"text":_vm.data['home_text_' + _vm.lang],"image":_vm.data.home_image,"image_caption":_vm.data['home_image_title_' + _vm.lang],"actions":[
            {
                title: _vm.$t('products.actions.our_products'),
                class: 'bg-accent mb-r',
                href: '#assortiment',
            },
            {
                title: _vm.$t('contact.menu'),
                to: 'Contact'
            }
        ]},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('div',{staticClass:"size-6 color-secondary mt-xl"},_vm._l((_vm.usps),function(usp){return _c('div',{key:'usp_' + usp.icon,staticClass:"flex align-center py-s"},[_c('div',{staticClass:"mr-m"},[_c('img',{staticClass:"w-32 h-32",attrs:{"src":'/img/icon_' + usp.icon + '.svg'}})]),_c('div',[_vm._v(" "+_vm._s(usp.usp)+" ")])])}),0)]},proxy:true}])}),_c('Introduction',{attrs:{"title":_vm.data['about_title_' + _vm.lang],"text":_vm.data['about_text_' + _vm.lang],"image1":_vm.data.about_image1,"image2":_vm.data.about_image2,"image_caption1":_vm.data['about_image1_title_' + _vm.lang],"image_caption2":_vm.data['about_image2_title_' + _vm.lang],"actions":[
            {
                title: _vm.$t('about.actions.more'),
                class: 'bg-accent',
                to: '/' + _vm.lang + '/over-ons',
            },
        ]}}),_c('div',{staticClass:"c w-xxl",attrs:{"id":"assortiment","name":"assortiment"}},[_c('Products',{attrs:{"list":_vm.data.products_categories,"to":"Assortiment"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }