<template>
    <div class="products flex flex-wrap columns align-stretch">
        <component :is="to ? 'router-link' : 'div'"
            v-for="item in sortedList"
            :key="'list_' + item.id"
            :to="{ name: to, params: { id: item.id, slug: kebabcase(item.name) }}"
            class="w-6 s:w-4 m:w-3 l:w-3 xl:w-2"
            :class="{ 'hover:opacity-75': to }"
        >
            <div
            class="ratio-1by1 rounded-s overflow-hidden"
            :class="{ 'bg-primary': item.image, 'bg-accent-3': !item.image }"
            >
                <img v-if="item.image" :src="cdnurl(item.image)" class="productcard__image object-cover w-12" loading="lazy" />
                <img v-else src="/img/logo_icon.svg" class="productcard__image productcard__image--placeholder" />
                <div class="px-l py-m color-white productcard__title">
                    {{ item.name }}
                    <div v-if="subtitleKey && item[subtitleKey]" class="color-tertiary size-6">{{ item[subtitleKey] }}</div>
                </div>
            </div>
        </component>
    </div>
</template>

<script>
import kebabcase from 'lodash.kebabcase';
import { arrays } from 'p5-helpers';

export default {
    name: 'ProductsList',
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        sorted: Array,
        to: String,
        subtitleKey: String,
    },
    methods: {
        kebabcase,
    },
    computed: {
        sortedList() {
            if (this.sorted) { return this.sorted; }
            return arrays.sortBy({ data: this.list, sortKey: 'name' });
        },
    },
};
</script>

<style>
    .productcard__title {
        top: auto;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    }
    a.productcard__image {
        mix-blend-mode: lighten;
    }
    .productcard__image--placeholder {
        mix-blend-mode: unset;
        width: 50%;
        height: 50%;
        margin: 25% 0 0 25%;
        opacity: 0.4;
    }
</style>
